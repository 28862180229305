import * as React from 'react'
import cn from 'classnames'
import styles from './CategoryRow.module.scss'
import { DivPropsWithoutChildren } from '../../../types/helpers'
import { useFramework } from '../../../hooks/use-framework'

type RootProps = {
  children: React.ReactNode
  uncategorised?: boolean
} & DivPropsWithoutChildren

const Root: React.VFC<RootProps> = (props) => {
  const { children, className, uncategorised, ...restProps } = props

  const [sticky] = useFramework((hook) => [hook.sticky])

  return (
    <div
      data-element-id="category-row"
      className={cn(styles.categoryRow, sticky && styles.collapsed, className)}
      {...restProps}
    >
      {children}
    </div>
  )
}

type TitleProps = {
  children: React.ReactNode
} & DivPropsWithoutChildren

const Title: React.VFC<TitleProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <h3 className={cn(styles.heading, className)} {...restProps}>
      {children}
    </h3>
  )
}

export { Root, Title }
