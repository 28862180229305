import * as React from 'react'
import cn from 'classnames'
import styles from './SkillCard.module.scss'
import * as Card from './Card'
import type { TSkill } from '../../../types/entities'

export type SkillCardProps = {
  skill: TSkill
} & Omit<Card.RootProps, 'children'>

export const SkillCard: React.VFC<SkillCardProps> = (props) => {
  const { skill, onClick, className, ...restProps } = props

  return (
    <Card.Root
      onClick={onClick}
      showHover={!!onClick}
      className={cn(styles.skillCard, className)}
      {...restProps}
    >
      <Card.Header className={styles.title}>
        <h4>{skill.name}</h4>
      </Card.Header>
      {skill.description && (
        <Card.Body
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: skill.description }}
        />
      )}
    </Card.Root>
  )
}
