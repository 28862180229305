import * as React from 'react'
import shallow from 'zustand/shallow'
import { useFramework } from '../../../hooks/use-framework'
import * as CategoryRow from './CategoryRow'
import { SkillRow } from './SkillRow'

export const UncategorisedSkills: React.VFC = () => {
  const uncategorisedSkills = useFramework(
    (s) => s.uncategorisedSkills,
    shallow
  )

  if (uncategorisedSkills.length === 0) return null

  const lastInCategory = (index: number) => {
    return index === uncategorisedSkills.length - 1
  }

  return (
    <>
      <CategoryRow.Root uncategorised>
        <CategoryRow.Title>Uncategorised skills</CategoryRow.Title>
      </CategoryRow.Root>
      {uncategorisedSkills.map((skill, index) => (
        <SkillRow
          uncategorised
          skill={skill}
          key={`skill-${skill.id}`}
          lastInCategory={lastInCategory(index)}
        />
      ))}
    </>
  )
}
