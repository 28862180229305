import * as React from 'react'
import { useFramework } from '../../../hooks/use-framework'
import * as CategoryRow from './CategoryRow'
import { SkillRow } from './SkillRow'
import shallow from 'zustand/shallow'

export const CategorisedSkills: React.VFC = () => {
  const [categories] = useFramework(
    (s) => [
      s.categories,
      s.uncategorisedSkills,
      s.eventHandlers,
      s.permissions,
    ],
    shallow
  )

  return (
    <>
      {categories.map((category, _categoryIndex) => (
        <React.Fragment key={category.id}>
          <CategoryRow.Root>
            <CategoryRow.Title>{category.name}</CategoryRow.Title>
          </CategoryRow.Root>
          {category.skills?.map((skill, categorySkillIndex) => {
            const lastInCategory = (categorySkillIndex: number) => {
              return (
                !!category.skills &&
                categorySkillIndex === category.skills.length - 1
              )
            }

            return (
              <SkillRow
                skill={skill}
                key={`skill-${skill.id}`}
                lastInCategory={lastInCategory(categorySkillIndex)}
              />
            )
          })}
        </React.Fragment>
      ))}
    </>
  )
}
