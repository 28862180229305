import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from '../../../types/helpers'
import { useFramework } from '../../../hooks/use-framework'
import { RequirementCard } from './RequirementCard'
import { TSkill } from '../../../types/entities'
import styles from './SkillRow.module.scss'
import { SkillCard } from './SkillCard'
import shallow from 'zustand/shallow'
import { Spacer } from './Spacer'

export type SkillRowProps = {
  skill: TSkill
  uncategorised?: boolean
  /**
   * Used for handling styles specific to the last skill in a category
   */
  lastInCategory?: boolean
} & DivPropsWithoutChildren

export const SkillRow: React.VFC<SkillRowProps> = (props) => {
  const {
    skill,
    className,
    uncategorised = false,
    lastInCategory = false,
    ...restProps
  } = props

  const [
    disciplines,
    requirements,
    getSortedPositionsByDiscipline,
    eventHandlers,
  ] = useFramework(
    (s) => [
      s.disciplines,
      s.requirements,
      s.getSortedPositionsByDiscipline,
      s.eventHandlers,
    ],
    shallow
  )

  return (
    <div
      className={cn(
        styles.skillRow,
        { [styles.lastInCategory]: lastInCategory },
        className
      )}
      {...restProps}
    >
      <SkillCard
        skill={skill}
        onClick={() => eventHandlers?.skillClickHandler?.(skill)}
      />
      {disciplines.map((discipline) => {
        const sortedPositions = getSortedPositionsByDiscipline(discipline)

        if (sortedPositions.length === 0)
          return (
            <React.Fragment key={`discipline-${discipline.id}`}>
              {/* Width of a filled position card */}
              <Spacer width="240px" />
              {/* 31px to include border, providing a gap between the empty spacer/next column */}
              <Spacer width="31px" />
            </React.Fragment>
          )

        return (
          <React.Fragment key={`discipline-${discipline.id}`}>
            {sortedPositions.map((position, index) => {
              const requirement = requirements?.find(
                (req) =>
                  req.skillId === skill.id && req.positionId === position.id
              )

              const key = requirement
                ? `requirement-${requirement.id}`
                : `empty-requirement-${index}`

              return (
                <RequirementCard
                  key={key}
                  requirement={requirement}
                  skill={skill}
                  position={position}
                  uncategorised={uncategorised}
                />
              )
            })}

            {discipline !== disciplines[disciplines.length - 1] && <Spacer />}
          </React.Fragment>
        )
      })}
    </div>
  )
}
