import * as React from 'react'
import shallow from 'zustand/shallow'
import cn from 'classnames'
import type { TPosition, TRequirement, TSkill } from '../../../types/entities'
import type { DivPropsWithoutChildren } from '../../../types/helpers'
import { useFramework } from '../../../hooks/use-framework'
import styles from './SkillBlobs.module.scss'

export type SkillBlobsProps = {
  requirement?: TRequirement
  position: TPosition
  skill: TSkill
  temporaryLevel: number | null
  setTemporaryLevel: (level: number | null) => void
  setErrorMessage: (errorMessage: string | null) => void
} & DivPropsWithoutChildren

export const SkillBlobs: React.VFC<SkillBlobsProps> = (props) => {
  const {
    position,
    requirement,
    skill,
    temporaryLevel,
    setTemporaryLevel,
    setErrorMessage,
    ...restProps
  } = props

  const [eventHandlers, permissions] = useFramework(
    (s) => [s.eventHandlers, s.permissions],
    shallow
  )

  const requirementLevel = requirement?.level || 0

  const maxSkillLevel =
    skill.skillLevels?.reduce((max, { level }) => Math.max(max, level), 0) || 0

  if (!requirement && !permissions?.allowCreateRequirement) {
    return null
  }

  return (
    <div className={styles.wrapper} {...restProps}>
      {Array.from({ length: maxSkillLevel }, (_, index) => {
        let isFilled = false
        const blobLevel = index + 1
        const hasRequirementAtLevel = Boolean(
          skill.skillLevels?.find((s) => s.level === blobLevel)
        )

        // if the current requirement level is higher than this blob's level, it should
        // be filled, unless there is a temporary level set, in which case skip this check
        if (requirementLevel >= blobLevel && !temporaryLevel) isFilled = true

        // if a temporary level exists (ie hovering over a blob that's different to the
        // existing requirement level, check to see if this is the same or greater than
        // this blob's level, and if it is make sure it's filled
        if (!!temporaryLevel && temporaryLevel >= blobLevel) isFilled = true

        let canChangeRequirement = false

        if (hasRequirementAtLevel && permissions?.allowChangeRequirement) {
          canChangeRequirement = true
        }

        if (!requirement && permissions?.allowCreateRequirement) {
          canChangeRequirement = true
        }

        const cursorStyles = {
          [styles.notAllowed]: !hasRequirementAtLevel,
          [styles.defaultCursor]: !canChangeRequirement,
        }

        return (
          <div
            className={cn(
              styles.skillBlobWrapper,
              !requirement && styles.empty,
              cursorStyles
            )}
            onMouseOver={() => {
              if (canChangeRequirement) setTemporaryLevel(blobLevel)
            }}
            onMouseOut={() => {
              if (canChangeRequirement) setTemporaryLevel(null)
            }}
            key={index}
          >
            <button
              type="button"
              className={cn(
                styles.skillBlob,
                {
                  [styles.filled]: isFilled,
                },
                cursorStyles
              )}
              onFocus={() => {
                if (canChangeRequirement) setTemporaryLevel(blobLevel)
              }}
              onBlur={() => {
                if (canChangeRequirement) setTemporaryLevel(null)
              }}
              disabled={!canChangeRequirement}
              onClick={async (e) => {
                e.stopPropagation()

                // if the user doesn't have permission to delete a requirement and the new level is the same
                // as the existing one, clicking it shouldn't do anything
                if (
                  !permissions?.allowDeleteRequirement &&
                  requirement &&
                  blobLevel === requirement.level
                ) {
                  return
                }

                // prevent onBlur or onMouseOut from setting the temporary level to null
                // value is set back when component is refreshed
                canChangeRequirement = false
                setTemporaryLevel(blobLevel)
                setErrorMessage(null)

                const existingRequirement = { ...requirement } as TRequirement

                const error = await eventHandlers?.onChangeRequirementLevel?.({
                  newLevel: blobLevel,
                  requirement: existingRequirement,
                  positionId: position.id as number,
                  skillId: skill.id,
                })

                if (error) setErrorMessage(error)
              }}
              aria-label={
                hasRequirementAtLevel
                  ? `Change the requirement level for the ${skill.name} skill in the ${position.name} position to level ${blobLevel}`
                  : `There is no level ${blobLevel} requirement for the ${skill.name} skill in the ${position.name} position`
              }
            />
          </div>
        )
      })}
    </div>
  )
}
