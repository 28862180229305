import * as React from 'react'
import cn from 'classnames'
import { TPosition, TRequirement, TSkill } from '../../../types/entities'
import { DivPropsWithoutChildren } from '../../../types/helpers'
import { useFramework } from '../../../hooks/use-framework'
import styles from './RequirementCard.module.scss'
import { SkillBlobs } from './SkillBlobs'
import * as Card from './Card'
import shallow from 'zustand/shallow'
import { MinusCircle } from '@phosphor-icons/react'

export type RequirementCardProps = {
  /**
   * If a requirement id isn't passed, we can use that to apply empty card logic
   */
  requirement?: TRequirement
  skill: TSkill
  position: TPosition
  uncategorised?: boolean
} & DivPropsWithoutChildren

export const RequirementCard: React.VFC<RequirementCardProps> = (props) => {
  const {
    skill,
    uncategorised,
    requirement,
    position,
    className,
    ...restProps
  } = props

  const [permissions, eventHandlers] = useFramework(
    (s) => [s.permissions, s.eventHandlers],
    shallow
  )

  const [temporaryLevel, setTemporaryLevel] = React.useState<number | null>(
    null
  )

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const allowClickingRequirement = React.useMemo(() => {
    if (!!requirement && permissions?.allowChangeRequirement) return true
    if (!requirement && permissions?.allowCreateRequirement) return true
    if (requirement?.level) return true
  }, [requirement, permissions])

  return (
    <div
      className={cn(styles.wrapper, 'group')}
      data-element-id="requirement-card"
    >
      <Card.Root
        className={cn(
          styles.requirementCard,
          allowClickingRequirement && styles.showHover,
          {
            [styles.hovering]:
              allowClickingRequirement &&
              !!temporaryLevel &&
              temporaryLevel !== requirement?.level,
            [styles.empty]: !requirement,
          },
          className
        )}
        onClick={() => {
          if (allowClickingRequirement) {
            eventHandlers?.requirementClickHandler?.({
              requirement,
              position,
              skill,
            })
          }
        }}
        tabIndex={allowClickingRequirement ? 0 : undefined}
        showHover={allowClickingRequirement}
        aria-label={`Requirement card for the ${skill.name} skill in the ${position.name} position.`}
        {...restProps}
      >
        <Card.Header className={cn(styles.header, className)} {...restProps}>
          <div className={styles.blobWrapper}>
            <SkillBlobs
              skill={skill}
              position={position}
              requirement={requirement}
              temporaryLevel={temporaryLevel}
              setTemporaryLevel={setTemporaryLevel}
              setErrorMessage={setErrorMessage}
            />
            {requirement &&
              eventHandlers?.onDeleteRequirement &&
              permissions?.allowDeleteRequirement && (
                <a
                  className={styles.dismissLink}
                  onClick={(e) => {
                    e.stopPropagation()
                    eventHandlers?.onDeleteRequirement?.(requirement)
                  }}
                >
                  <span className="sr-only">Delete requirement</span>
                  <MinusCircle
                    aria-hidden
                    className={cn(
                      styles.dismiss,
                      'opacity-0',
                      'group-hover:opacity-100',
                      'focus-within:opacity-100'
                    )}
                  />
                </a>
              )}
          </div>
        </Card.Header>
        <RequirementCardBody
          skill={skill}
          position={position}
          requirement={requirement}
          errorMessage={errorMessage}
          temporaryLevel={temporaryLevel}
          uncategorised={uncategorised}
        />
      </Card.Root>
    </div>
  )
}

type RequirementCardBodyProps = {
  uncategorised?: boolean
  skill: TSkill
  position: TPosition
  level?: number
  requirement?: TRequirement
  temporaryLevel: number | null
  errorMessage: string | null
}

const RequirementCardBody: React.VFC<RequirementCardBodyProps> = (props) => {
  const { skill, requirement, errorMessage, temporaryLevel } = props

  const [permissions, getSkillLevelDescription] = useFramework(
    (s) => [s.permissions, s.getSkillLevelDescription],
    shallow
  )

  const content = () => {
    if (errorMessage) {
      return <p className={styles.description}>{errorMessage}</p>
    }

    if (
      !requirement &&
      !temporaryLevel &&
      permissions?.allowCreateRequirement
    ) {
      return (
        <p className={styles.addRequirement}>
          Add requirement{' '}
          <span className={styles.addRequirementIcon} aria-hidden>
            +{' '}
          </span>
        </p>
      )
    }

    if (temporaryLevel || (requirement && requirement.level)) {
      const currentLevel = temporaryLevel || requirement?.level || 0
      let description = getSkillLevelDescription(skill, currentLevel) || ''
      const hasEmptyDescription = !description

      if (hasEmptyDescription) description = 'Empty description'

      return (
        <div
          className={cn(styles.description, {
            [styles.emptyDescription]: hasEmptyDescription,
          })}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )
    }
  }

  return <Card.Body>{content()}</Card.Body>
}
