import cn from 'classnames'
import * as React from 'react'
import { useFramework, UseFrameworkProps } from '../../../hooks/use-framework'
import { CategorisedSkills } from './CategorisedSkills'
import { DisciplinesRow } from './DisciplinesRow'
import styles from './FrameworkPage.module.scss'
import { UncategorisedSkills } from './UncategorisedSkills'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import shallow from 'zustand/shallow'
import { Plus } from '@phosphor-icons/react'

export type FrameworkPageProps = Pick<
  UseFrameworkProps,
  | 'categories'
  | 'disciplines'
  | 'eventHandlers'
  | 'frameworkId'
  | 'permissions'
  | 'requirements'
  | 'uncategorisedSkills'
> & { className?: string }

export const FrameworkPage: React.VFC<FrameworkPageProps> = (props) => {
  const scrollPosition = React.useRef(0)
  const { className, ...frameworkProps } = props

  const [setSticky] = useFramework((hook) => [hook.setSticky], shallow)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSticky(scrollPosition.current > 10)
    }, 200)

    return () => clearInterval(interval)
  }, [])

  React.useEffect(() => {
    // TODO: replace this with internal method so we can set state initially outside of use effect hook
    useFramework.setState(frameworkProps)
  }, [props])

  return (
    <ScrollSync
      vertical={false}
      onSync={(el) => {
        if (el.id === 'framework-body') scrollPosition.current = el.scrollTop
      }}
    >
      <div className={cn(styles.frameworkPage, className)}>
        <ScrollSyncPane>
          <DisciplinesRow disciplines={props.disciplines} />
        </ScrollSyncPane>
        <ScrollSyncPane>
          <div className={styles.scrollWrapper} id="framework-body">
            <CategorisedSkills />
            <UncategorisedSkills />
            {props.permissions?.allowAddSkillCategory && (
              <div className={cn('')}>
                <div className={cn('')}>
                  <button
                    className={styles.cta}
                    onClick={() =>
                      props.eventHandlers?.onClickAddSkillCategory?.()
                    }
                    type="button"
                  >
                    <Plus weight="bold" className={styles.icon} aria-hidden />
                    Add a category
                  </button>
                </div>
              </div>
            )}
          </div>
        </ScrollSyncPane>
      </div>
    </ScrollSync>
  )
}
